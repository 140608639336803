import React from "react";
import Header from "../components/Header";
import Img2 from "../img/Contact.png";
import Map from "../components/Map";
import Form from "../components/Form";
import Helmet from "react-helmet";
const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arian Traders - Contact</title>
        <meta
          name="description"
          content="+ 880-1811530562, ariantraders1@gmail.com"
        />
      </Helmet>
      <div className="flex flex-col gap-5 mb-10">
        <Header
          src={Img2}
          alt="Contact"
          title="Contacts"
          text="Home / Contacts"
          contact
        />
        <Form />
        <Map />
      </div>
    </>
  );
};

export default Contact;

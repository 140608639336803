import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
const Counter = () => {
  const [counterOn, setCounterOn] = useState(false);
  const elements = [
    {
      end: 20,
      text: "Completed Projects",
    },
    {
      end: 40,
      text: "Equipments",
    },
  ];
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="grid grid-cols-2 w-max mx-auto">
        {elements.map((el, i) => {
          return (
            <div
              key={i}
              className={`text-white font-Lato p-5 md:p-8 ${
                i % 2 === 0 ? "bg-sky-950" : "bg-red-700"
              }`}
            >
              <p className=" text-4xl md:text-6xl font-bold">
                {counterOn && (
                  <CountUp start={0} end={el.end} duration={2} delay={0} />
                )}
                +
              </p>
              <p className="font-[Lato]">{el.text}</p>
            </div>
          );
        })}
      </div>
    </ScrollTrigger>
  );
};

export default Counter;

import React from "react";
import Industry from "../../img/industry_building.png";
import EastWest from "../../img/EastWest.png";
import img3 from "../../img/Rounded_Crane.png";
import img4 from "../../img/Office_Building.png";
import img5 from "../../img/Large_Buildings.png";
import { Fade } from "react-reveal";
const FourthDIv = () => {
  const imgarray = [
    { img: img3, alt: "Dredger" },
    { img: img4, alt: "Under construction building" },
    { img: EastWest, alt: "Blueprint" },
    { img: img5, alt: "Area" },
  ];
  return (
    <div className="text-center font-lato flex flex-col gap-3">
      <p className="text-3xl text-[#16213E] font-bold font-[Kanit]">
        Our Special Projects
      </p>
      <p className="text-2xl text-[#D2153D] font-semibold font-[Lato]">
        East West Property
      </p>

      <div className="flex flex-col lg:grid lg:grid-cols-3 m-auto gap-3 w-11/12 md:w-2/3 items-center">
        <Fade left>
          <img alt="Under construction building" src={Industry} />
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 col-span-2">
          {imgarray.map((item, i) => (
            <div>
              {/* {index === 2 ? (
                <div
                  style={{ backgroundImage: `url(${item.img})` }}
                  className="w-[380px] h-[320px]"
                >
                  <div className="bg-[#00215BD9] w-full h-full bg-opacity-50 pt-20">
                    <p className="text-[#FFFFFF] text-center font-Lato text-[17px] ">
                      Commercial
                    </p>
                    <p className="w-11/12 mt-2 text-[#FFFFFF] justify-center ml-[80px] text-[28px] font-Lato pr-8">
                      East West Property
                    </p>
                  </div>
                </div>
              ) : ( */}
              <Fade right>
                <img src={item.img} alt={item.alt} />
              </Fade>
              {/* )} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FourthDIv;

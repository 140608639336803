import React from "react";
import Field from "../../Video/Field.mp4";
import img1 from "../../img/image 1.png";
import { Slide } from "react-reveal";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const SecondDiv = () => {
  return (
    <div className="lg:mb-8 flex flex-col-reverse lg:flex-row lg:gap-3 items-center w-full md:w-5/6 lg:w-2/3 m-auto">
      <Slide left duration={2000}>
        <div className="flex flex-col gap-3 items-center -mt-8 lg:mt-0">
          <div className="flex flex-col gap-3 text-center lg:text-left items-center lg:items-start">
            <p className="text-[#D2153D] font-[Kanit] font-semibold">
              ABOUT US
            </p>

            <p className="text-[32px] font-bold font-[Kanit]">
              With our knowledge <br />
              we guarantee success.
            </p>

            <p className="text-[#00215B] font-[Lato] font-bold text-[17px]">
              We thrive in a world of constant change.
            </p>
            <p className="w-5/6 lg:w-2/3 font-[Lato] text-[17px] mt-6 lg:pr-2">
              We're not just a construction company; we're innovators. Embracing
              new technologies, pioneering fresh ideas, and adapting to market
              shifts are at the core of what we do. Our commitment is
              unwavering: to serve you, our valued client, with excellence.
              Discover how we're redefining construction for a dynamic world.
            </p>
          </div>
          <Link
            to="/about"
            className="flex font-[Kanit] lg:mr-auto px-5 py-2 text-lg text-white bg-[#B92F2C] hover:scale-110 transition-transform duration-300"
          >
            Learn More{" "}
            <Slide left duration={2500}>
              <ArrowRightAltIcon />
            </Slide>
          </Link>
        </div>
      </Slide>

      <video
        className="h-[175px] bg-white p-2 shadow-lg lg:absolute z-10 lg:right-72 lg:translate-y-32 -translate-y-10"
        autoPlay
        loop
        muted
        preload="auto"
        src={Field}
      />

      <img
        className="h-[250px] bg-white p-2 shadow-lg"
        src={img1}
        alt="Shipment"
      />
    </div>
  );
};

export default SecondDiv;

import React from "react";
import logo from "../img/Logo.svg";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import TextsmsIcon from "@mui/icons-material/Textsms";
const Footer = () => {
  const contacts = [
    {
      text: "Call for us",
      icon: <CallIcon />,
      link: "tel:+8801811530562",
      linkText: "+880-1811530562",
    },
    {
      text: "Connect with us",
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=61551860112904&mibextid=LQQJ4d",
      linkText: "Arian Traders",
    },
    {
      text: "Email us",
      icon: <TextsmsIcon />,
      link: "mailto:ariantraders1@gmail.com",
      linkText: "ariantraders1@gmail.com",
    },
  ];
  return (
    <div className="py-3 text-center bg-[#B92F2C] text-white">
      <div className=" text-white text-4xl font-medium leading-10 font-[Kanit]">
        Arian Tradings
      </div>{" "}
      <div className="grid grid-cols-1 md:flex gap-3">
        <div className="mx-16">
          <img
            className="w-56 h-56 md:w-72 md:h-72 m-auto"
            src={logo}
            alt="Logo"
          />
        </div>
        <div className="flex flex-col gap-10 md:gap-16 w-full items-center my-auto">
          <div className="flex flex-col md:flex-row gap-10 h-full w-full justify-evenly">
            {contacts.map((el, i) => {
              return (
                <div
                  key={i}
                  className="text-center  flex flex-col gap-3 md:gap-5"
                >
                  <p className="font-[Kanit]">{el.text}</p>
                  {i === 1 ? (
                    <a href={el.link} target="_blank" rel="noreferrer">
                      {el.icon}{" "}
                      <span className="text-lg ml-1 font-[Kanit]">
                        {el.linkText}
                      </span>
                    </a>
                  ) : (
                    <a href={el.link}>
                      {el.icon}{" "}
                      <span className="text-lg ml-1 font-[Kanit]">
                        {el.linkText}
                      </span>
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <p className="text-center text-sm">
        Done By{" "}
        <Link
          className="underline underline-offset-2 font-[Kanit]"
          to="https://www.kreatech.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          KREATECH.CA
        </Link>
      </p>
    </div>
  );
};

export default Footer;

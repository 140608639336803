import React from "react";
import video from "../../Video/Excavator.mp4";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Slide, Fade } from "react-reveal";
import { Link } from "react-router-dom";
import Engineer from "../../img/engineer.png";
import Ruler from "../../img/ruler.png";
import Tools from "../../img/tools.png";
import Counter from "../../components/Counter";
const FirstDiv = () => {
  const activities = [
    {
      img: Engineer,
      alt: "Random",
      title: "General Contract ",
      description:
        "Using the best technologies of the world at our construction site.",
    },
    {
      img: Ruler,
      alt: "Random",
      title: "Project Planning",
      description: "Strictly adherence to safety and quality of our services.",
    },
    {
      img: Tools,
      alt: "Random",
      title: "Strategy",
      description:
        "Sustain client priorities of our clients and be functionally state-of-art with integrity.",
    },
  ];
  return (
    <div className="flex flex-col">
      <video
        className="object-cover w-full h-[750px] lg:h-full opacity-90 grayscale-[50%] md:grayscale-[30%] brightness-50"
        autoPlay
        loop
        muted
        preload="auto"
        src={video}
      />
      <Slide top duration={2000}>
        <div className="absolute top-[19.5%] left-[5%] md:left-[18%] flex flex-col gap-6 text-white font-Lato w-[90%] md:w-[65%] lg:w-[55%]">
          <p className="text-2xl md:text-3xl lg:text-4xl text-[#B92F2C] font-[Kanit] font-bold">
            Arian Traders
          </p>

          <p className="font-bold font-[Kanit] text-4xl md:text-5xl lg:text-6xl">
            All kinds of Soil <br />
            Filling by Dredger.
          </p>

          <div className="flex flex-col gap-3 w-[90%] md::w-1/2 font-[Lato] text-lg">
            We live in a world of continual change. New technologies substitute
            old, fresh thinking supplants former ideas and market shifts drive
            us to adapt new approaches on how we are to best operate our
            business. Being a leading construction company, it is important to
            obligate ourselves to our valued clients.
            <Link
              to="/about"
              className="flex font-[Kanit] w-max px-5 py-2 text-lg text-white bg-[#B92F2C] hover:scale-110 transition-transform duration-300"
            >
              Learn More{" "}
              <Fade left>
                <ArrowRightAltIcon />
              </Fade>
            </Link>
          </div>
        </div>
      </Slide>

      <div className="flex flex-col lg:flex-row justify-center mx-auto">
        <Counter />

        <div className="flex flex-col gap-3 bg-white lg:-mt-[75px] shadow-lg z-10 md:p-2 font-[Lato]">
          {activities.map((el, i) => {
            return (
              <div key={i} className="flex gap-3 p-2">
                <img className="w-16 h-16" src={el.img} alt={el.alt} />

                <div>
                  <p className="text-[#00215B] font-bold">{el.title}</p>
                  <p className="w-[250px] md:w-[320px] leading-6">
                    {el.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FirstDiv;

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { ToastContainer, toast } from "react-toastify";
import { Fade } from "react-reveal";
import "react-toastify/dist/ReactToastify.css";
const Form = ({ about }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const handleEvent = (e) => {
    e.preventDefault();

    if (
      username.length === 0 ||
      email.length === 0 ||
      phoneno.length === 0 ||
      address.length === 0 ||
      message.length === 0
    ) {
      toast("Please Fill out the fields");
    } else {
      emailjs
        .sendForm(
          "service_wia3pcc",
          "template_gkmjnsb",
          e.target,
          "3IGbf-ZCICggQ1b_6"
        )
        .then(
          (result) => {
            console.log(result.text);
            toast("Message Sent Successfully!");
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };
  return (
    <div className="flex flex-col gap-6 w-5/6  md:3/4 lg:w-1/2 mt-12 mx-auto font-[Kanit]">
      {about && (
        <div className=" font-bold font-lato flex flex-col gap-3">
          <p className="text-[#B92F2C]">APPOINTMENT</p>

          <p className="text-[#16213E] text-3xl">Request a Quote</p>
        </div>
      )}

      <form onSubmit={handleEvent} className="font-lato flex flex-col gap-3">
        <div className="flex gap-3">
          <input
            type="text"
            name="name"
            onChange={(e) => setUsername(e.target.value)}
            id="name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Full Name"
            required
          />
          <input
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Email Address"
            required
          />
        </div>
        <div className="flex">
          <input
            type="tel"
            name="telephone"
            onChange={(e) => setPhoneno(e.target.value)}
            id="telephone"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Your Phone No."
            required
          />
          {about ? (
            <></>
          ) : (
            <input
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              name="address"
              id="address"
              class="ml-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your Address"
              required
            />
          )}
        </div>

        <div className="">
          <textarea
            name="message"
            id="message"
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            class="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Your Message Here..."
            required
          />
        </div>
        <button
          className={
            "flex flex-row font-lato w-max px-6 py-3 text-lg text-white rounded-sm bg-[#B92F2C] hover:scale-110 transition-transform duration-300"
          }
        >
          {about ? <span>Appointment</span> : <span>Submit Message</span>}
          <Fade left>
            <ArrowRightAltIcon />
          </Fade>
        </button>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Form;

import React from "react";
import work_1 from "../img/work_1.jpg";
import Header from "../components/Header";
import Helmet from "react-helmet";
import FirstDivW from "./components/FirstDivW";
import Bashundhara from "../img/Bashundhara.png";
import EastWest from "../img/EastWestW.png";
import { motion } from "framer-motion";
const Work = () => {
  const companies = [
    {
      img: Bashundhara,
      alt: "Bashundara",
      title: "Bashundhara Group",
    },
    {
      img: EastWest,
      alt: "EastWest",
      title: "East West Property Development",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arian Traders - Our Work</title>
        <meta
          name="description"
          content="We're not just a construction company; we're innovators. Embracing new technologies, pioneering fresh ideas, and adapting to market shifts are at the core of what we do. Our commitment is unwavering: to serve you, our valued client, with excellence. Discover how we're redefining construction for a dynamic world."
        />
      </Helmet>
      <div className="flex flex-col gap-5 mb-10">
        <Header
          src={work_1}
          alt={"Our-Work"}
          title={"Work Archive"}
          text={"Home / Work"}
        />

        <FirstDivW />

        <div className="text-center flex flex-col gap-6">
          <p className="text-3xl md:text-4xl lg:text-5xl font-bold font-[Kanit] text-[#B92F2C]">
            Our Esteemed Clients
          </p>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 items-center w-1/2 md:w-3/4 m-auto">
              {companies.map((item, index) => {
                return (
                  <div key={index} className="m-auto">
                    <img className={`m-auto`} src={item.img} alt={item.alt} />

                    <p className={`font-[Lato] text-[18px] font-[400] mt-3`}>
                      {item.title}
                    </p>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Work;

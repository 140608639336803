import React from "react";
import Map from "../components/Map";
import FirstDiv from "./components/FirstDiv";
import SecondDiv from "./components/SecondDiv";
import ThirdDiv from "./components/ThirdDiv";
import Helmet from "react-helmet";
import FifthDiv from "./components/FifthDiv";
import FourthDIv from "./components/FourthDIv";
const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arian Traders</title>
        <meta
          name="description"
          content="We're not just a construction company; we're innovators. Embracing new technologies, pioneering fresh ideas, and adapting to market shifts are at the core of what we do. Our commitment is unwavering: to serve you, our valued client, with excellence. Discover how we're redefining construction for a dynamic world."
        />
      </Helmet>
      <div className="flex flex-col gap-10 mb-10">
        <FirstDiv />

        <SecondDiv />

        <ThirdDiv />

        <FourthDIv />

        <Map />

        <FifthDiv />
      </div>
    </>
  );
};

export default Home;

import React from "react";
import about_1 from "../img/about_1.png";
import a1 from "../img/a1.png";
import a2 from "../img/a2.png";
import a_activity_1 from "../img/a_activity_1.png";
import a_activity_2 from "../img/a_activity_2.png";
import a_activity_31 from "../img/a_activity_31.png";
import a_activity_32 from "../img/a_activity_32.png";
import a_activity_33 from "../img/a_activity_33.png";
import Header from "../components/Header";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import Form from "../components/Form";
import Helmet from "react-helmet";
import { motion } from "framer-motion";
const About = () => {
  const about = [
    {
      no: "01",
      title: "Kind Consulation",
    },
    {
      no: "02",
      title: "Requirement Analysis",
    },
    {
      no: "03",
      title: "Project Launch",
    },
  ];

  const activities = [
    {
      img: a_activity_1,
      alt: "SoilFill",
      title: "Soil Filling Excellence",
      description:
        "We understand that proper soil preparation is essential for the success of any construction project.",
    },
    {
      img: a_activity_2,
      alt: "Equipments",
      title: "Reliable Equipment Supply Partner",
      description:
        "Our extensive range of construction equipment is carefully curated.",
    },
    {
      img1: a_activity_31,
      img2: a_activity_32,
      img3: a_activity_33,
      alt: "Bricks",
      title: "Brick and Soil Supply Excellence",
      description:
        "From traditional red bricks to modern and specialty bricks, we have an extensive inventory to choose from.",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arian Traders - About</title>
        <meta
          name="description"
          content="We're not just a construction company; we're innovators. Embracing new technologies, pioneering fresh ideas, and adapting to market shifts are at the core of what we do. Our commitment is unwavering: to serve you, our valued client, with excellence. Discover how we're redefining construction for a dynamic world."
        />
      </Helmet>
      <div className="flex flex-col gap-5 mb-10">
        <Header src={about_1} title={"About Us"} text={"Home / About Us"} />

        {/* Second div */}
        <div className="m-auto my-24">
          <div className="flex flex-col md:flex-row gap-3 md:gap-6 justify-center items-center mx-auto">
            <img className="my-auto" src={a1} alt="Random" />

            <div className="text-center md:text-left w-[300px] lg:w-[320px] md:w-96 flex flex-col gap-3 justify-center  items-center md:items-start">
              <p className="text-[#D2153D] font-semibold font-[Lato]">
                About Us
              </p>
              <p className="text-[32px] font-bold font-[Kanit] leading-none">
                We're providing the best customer service.
              </p>

              <div className="bg-[#F9F9F9] p-4 flex flex-col gap-3">
                <p className="text-center md:text-left leading-6 font-[Lato]">
                  We believe that attentive customer service is not merely a
                  department but a mindset embedded in every member of our team.
                  From the first consultation to the final handover, we're here
                  to listen, collaborate, and deliver on your vision. Your
                  satisfaction is our measure of success.
                </p>
                <p className="text-xl">
                  <span className="font-bold font-[Kanit]">
                    Md. Hafijul Islam
                  </span>
                  , Proprietor
                </p>
              </div>
              <Link
                to="/service"
                className="flex font-lato w-max px-5 py-2 text-lg text-white bg-[#B92F2C] hover:scale-110 transition-transform duration-300"
              >
                Learn More{" "}
                <Fade left>
                  <ArrowRightAltIcon />
                </Fade>
              </Link>
            </div>
          </div>
        </div>

        {/* Third div */}
        <div>
          <div
            style={{ backgroundImage: `url(${a2})` }}
            className="text-[#FFFFFF] bg-cover h-screen flex justify-center items-center"
          >
            <Fade bottom>
              <div className="text-center font-Lato flex flex-col gap-6 justify-center items-center w-[60%]">
                <p className="text-2xl lg:text-2xl font-[Kanit]">Get a Quote</p>
                <p className="text-3xl md:text-4xl lg:text-5xl font-bold font-[Kanit]">
                  Build Your Future Today
                </p>
                <p className="text-lg font-[Lato]">
                  We look forward to continuing this journey with you, serving
                  you with unwavering commitment, and making your construction
                  projects a resounding success.
                </p>
                <Link
                  to="/contact"
                  className="font-[Kanit] w-max px-5 py-2 text-lg text-white bg-[#B92F2C] hover:scale-110 transition-transform duration-300 flex"
                >
                  Appointment{" "}
                  <Fade left>
                    <ArrowRightAltIcon />
                  </Fade>
                </Link>
              </div>
            </Fade>
          </div>

          <div className="-mt-14 w-max grid grid-cols-1 lg:grid-cols-3 gap-5 justify-center m-auto">
            {about.map((el, i) => {
              return (
                <div key={i} className="bg-white flex gap-3 p-8 shadow-lg">
                  <div className="text-2xl bg-[#B92F2C] p-2 font-[Kanit] font-extrabold text-white">
                    {el.no}
                  </div>
                  <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
                    <p className="text-[#00215BD9] font-[Kanit] font-bold text-lg">
                      {el.title}
                    </p>
                  </motion.div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-3">
          <Form about />
          <div className="flex flex-col gap-3 bg-white shadow-lg w-max m-auto md:p-2">
            {activities.map((el, i) => {
              return (
                <div key={i} className="flex gap-3 p-2 font-[Lato]">
                  {i === 2 ? (
                    <div className="grid grid-cols-3 gap-2 w-16">
                      <img src={el.img1} alt={el.alt} />
                      <img src={el.img2} alt={el.alt} />
                      <img src={el.img3} alt={el.alt} />
                    </div>
                  ) : (
                    <div className="w-16">
                      <img src={el.img} alt={el.alt} />
                    </div>
                  )}

                  <div>
                    <p className="text-[#00215B] font-bold">{el.title}</p>
                    <p className="w-[250px] md:w-[320px] leading-6">
                      {el.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

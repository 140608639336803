import React from "react";
import Handshake from "../../img/Handshake.jpg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Fade } from "react-reveal";
const FifthDiv = () => {
  return (
    <div className="flex flex-col md:flex-row gap-3 md:gap-10 w-5/6 md:w-3/4 lg:w-[55%] items-center m-auto mb-10">
      <img
        className="w-[250px] h-[320px] bg-[#FFFFFF] p-2 shadow-lg"
        src={Handshake}
        alt="HandShake"
      />

      <Fade right>
        <div className="flex flex-col gap-3 text-center md:text-left">
          <p className="text-[#D2153D] font-[Kanit] font-semibold">
            TESTIMONIALS
          </p>
          <p className="text-[32px] text-[#16213E] font-[Kanit] font-bold">
            Our Clients Say
          </p>
          <div className="w-14 h-14 bg-[#001ED3] hidden lg:block">
            <FormatQuoteIcon
              style={{
                color: "#FFFFFF",
                marginLeft: "5px",
                marginTop: "5px",
                fontSize: "48px",
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p className="font-[Lato]">
              I can't thank Arian Traders enough for their outstanding work on
              our construction project. From the very beginning, they showed a
              commitment to understanding our unique needs and challenges. Their
              innovative approach was truly impressive.
            </p>
            <div className="text-[#16213E] font-[Kanit]">
              <p className="text-[20px] font-semibold">Md. Motaher Hossain</p>
              <p>Chief Engineer of East West Property</p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default FifthDiv;

import React from "react";
import service_1 from "../img/service_1.jpg";
import s1 from "../img/s1.jpg";
import s2 from "../img/s2.png";
import s3 from "../img/s3.jpg";
import s4 from "../img/s4.jpg";
import { Fade } from "react-reveal";
import Header from "../components/Header";
import Helmet from "react-helmet";
const Service = () => {
  const service = [
    {
      image: s1,
      title: "Soil Loading",
      alt: "Soil_loading",
      description:
        "Soil loading typically refers to the process of loading soil or earth materials, such as sand, gravel, or dirt, into trucks, containers, or other vehicles for transportation to construction sites, landscaping projects, or various other uses. Soil loading is a fundamental step in construction, agriculture, and many other industries that require the movement of large quantities of soil or similar materials.",
    },
    {
      image: s2,
      title: "Soil Unloading",
      alt: "Soild_Unloading",
      description:
        "“Soil unloading” refers to the process of removing or discharging soil, dirt, or other earth materials from trucks, containers, or other vehicles at a designated location. This process is typically the reverse of soil loading and is an important step in construction, landscaping, waste disposal, and other industries where soil or similar materials need to be transported to a specific destination.",
    },
    {
      image: s3,
      title: "Dregging",
      alt: "Dredeging",
      description:
        "The dredging process typically involves the use of specialized equipment, such as dredgers or excavators equipped with dredging attachments, to scoop up sediments from the waterbed. These sediments are then transported to designated disposal sites or used for other purposes, such as beach nourishment or land reclamation.",
    },
    {
      image: s4,
      title: "Equimment Supply",
      alt: "Equipment_Supply",
      description:
        "Equipment supply companies typically offer a wide range of equipment to cater to the diverse needs of their clients. This may include construction machinery (e.g., excavators, bulldozers), industrial tools, vehicles (e.g., trucks, forklifts), medical equipment, laboratory instruments, and more.",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Arian Traders - Service</title>
        <meta
          name="description"
          content="We're not just a construction company; we're innovators. Embracing new technologies, pioneering fresh ideas, and adapting to market shifts are at the core of what we do. Our commitment is unwavering: to serve you, our valued client, with excellence. Discover how we're redefining construction for a dynamic world."
        />
      </Helmet>
      <div className="flex flex-col gap-5 mb-10">
        <Header
          src={service_1}
          alt={"Service"}
          title={"Services Archive"}
          text={"Home / Service"}
        />

        <div className="m-auto my-24 text-white">
          {service.map((el, i) => {
            return (
              <div key={i}>
                <div className="lg:grid hidden lg:grid-cols-2 w-full md:w-[70%] lg:w-[80%] m-auto">
                  <Fade left={i % 2 === 0} right={i % 2 !== 0}>
                    <img
                      className={`${i % 2 !== 0 && "hidden"}`}
                      src={el.image}
                      alt={el.alt}
                    />
                    <div className="bg-[#B92F2C] flex flex-col gap-2 p-[18px] justify-center">
                      <p className="text-[28px] font-[Kanit]">{el.title}</p>
                      <p className="text-[15px] font-[Lato]">
                        {el.description}
                      </p>
                    </div>
                    <img
                      className={`${i % 2 === 0 && "hidden"}`}
                      src={el.image}
                      alt={el.alt}
                    />
                  </Fade>
                </div>
                <div className="flex flex-col lg:hidden w-5/6 m-auto">
                  <Fade left>
                    <img className="" src={el.image} alt={el.alt} />
                  </Fade>
                  <Fade right>
                    <div className="bg-[#B92F2C] flex flex-col gap-3 p-4">
                      <p className="text-[28px] font-[Kanit]">{el.title}</p>
                      <p className="text-[17px] font-[Lato]">
                        {el.description}
                      </p>{" "}
                    </div>
                  </Fade>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Service;

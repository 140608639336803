import React from "react";
import Crane from "../../img/crane.png";
import Houses from "../../img/Houses.png";
import img2 from "../../img/w9.png";
import Industry from "../../img/industry_building.png";
import { Fade } from "react-reveal";
const ThirdDiv = () => {
  const about = [
    {
      img: Industry,
      title: "Research",
      description:
        "We meticulously assess market trends, cutting-edge technologies, and innovative approaches. By integrating data-driven insights into our strategies, we ensure that every project we undertake is executed with precision and efficiency. Your success is our priority, and our commitment to thorough research and analysis is a testament to our dedication to delivering superior results.",
    },
    {
      img: Crane,
      title: "Development",
      description:
        "Our dedicated development team works tirelessly to bring visionary concepts to life, ensuring each endeavor contributes positively to the world around us. At Arian Traders, we're not just building structures; we're cultivating a better future through thoughtful development.",
    },
    {
      img: Houses,
      title: "Launch",
      description:
        "At Arian Traders, every project is a launch into a new era of possibilities. We meticulously plan, prepare, and execute with precision to ensure each endeavor takes flight smoothly. Whether it's a groundbreaking ceremony, the commencement of a new phase, or the unveiling of a completed project, our launch events are a celebration of our commitment to excellence.",
    },
  ];
  return (
    <div className="flex flex-row">
      <img className="hidden lg:block" src={img2} alt="Random" />

      <div className="grid grid-cols-1 md:grid-cols-3 col-span-2">
        {about.map((item, i) => {
          return (
            <div
              style={{ backgroundImage: `url(${item.img})` }}
              className="bg-cover"
            >
              <div
                className={`w-full h-full bg-opacity-50 p-5 lg:p-8 text-white font-Lato flex flex-col gap-3 ${
                  i % 2 === 0 ? "bg-[#00215BD9]" : "bg-[#B32B29D9]"
                }`}
              >
                <Fade top={i === 0 || i === 2} bottom={i === 1}>
                  <p className="text-3xl text-center font-[Kanit] font-semibold">
                    {item.title}
                  </p>
                  <p className="text-left font-[Lato]">{item.description}</p>
                </Fade>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThirdDiv;

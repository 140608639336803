import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/Logo.svg";
import HamburgerButton from "./Hamburger";

import "./Navbar.css";
const Navbar = () => {
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <div className="navbar-container">
      <div className="flex justify-between font-[Kanit] md:justify-evenly px-2 z-40 fixed top-0 left-0 right-0 bg-white h-16 shadow-md border-b-2">
        <div className="flex">
          <Link to="/" className="flex gap-1">
            <img
              className="w-12 h-12 md:w-14 md:h-14 m-auto"
              src={logo}
              alt="Logo"
            />
            <p className="font-semibold my-auto ml-1 text-xl font-lato">
              ARIAN TRADERS
            </p>
          </Link>
        </div>
        <div className="my-auto hidden md:block">
          <ul className="flex gap-2 lg:gap-7">
            {[
              {
                name: "Home",
                path: "/",
              },
              {
                name: "About",
                path: "/about",
              },
              {
                name: "Service",
                path: "/service",
              },
              {
                name: "Our work",
                path: "/work",
              },
              {
                name: "Contact",
                path: "/contact",
              },
            ].map((el, i) => (
              <li
                key={i}
                className={`text-lg lg:text-xl ${
                  location.pathname === el.path
                    ? "text-black font-bold border-b-2 border-black border-solid text-2xl"
                    : "text-[#00215B] hover:text-black hover:scale-110 transition-transform duration-300 cursor-pointer font-semibold"
                }`}
              >
                <Link to={el.path} className="px-2">
                  {el.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="my-auto block md:hidden">
          <HamburgerButton active={menuOpen} onClick={toggleMenu} />
        </div>
      </div>

      <div
        className={`mobile-menu m-auto h-full w-full flex justify-center items-center ${
          menuOpen ? "open" : ""
        }`}
        onClick={closeMenu}
      >
        <ul className="h-1/2 m-auto flex flex-col justify-evenly">
          {[
            {
              name: "Home",
              path: "/",
            },
            {
              name: "About",
              path: "/about",
            },
            {
              name: "Service",
              path: "/service",
            },
            {
              name: "Our work",
              path: "/work",
            },
            {
              name: "Contact",
              path: "/contact",
            },
          ].map((el, i) => (
            <li
              key={i}
              className={`text-2xl m-auto 
                ${
                  location.pathname === el.path
                    ? "text-[#B92F2C] font-bold border-b-2 border-[#B92F2C]"
                    : "text-white hover:text-black hover:scale-110 cursor-pointer font-semibold"
                }
                  `}
            >
              <Link to={el.path} className="px-2">
                {el.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

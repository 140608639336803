import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import CallIcon from "@mui/icons-material/Call";
const Header = ({ src, title, alt, text, contact }) => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0.5 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <img
          className="w-[100%] h-[250px] md:h-[400px] brightness-[.4]"
          src={src}
          alt={alt}
        />
      </motion.div>
      <Slide left duration={2000}>
        <div className="flex flex-col gap-3 md:gap-5 absolute top-0 text-white ml-16 md:ml-36 mt-28 md:mt-44">
          <p className="text-3xl md:text-4xl font-[Kanit] font-bold">{title}</p>
          <p className="font-[Lato]">{text}</p>
        </div>
      </Slide>
      {contact ? (
        <> </>
      ) : (
        <div className="flex flex-col font-[Kanit] gap-3 px-8 md:px-12 py-6 md:py-8 shadow-md absolute right-16 md:right-28 lg:right-80 top-52 md:top-[350px] bg-white border-t-4 border-[#B92F2C]">
          <p>CALL US TODAY</p>
          <Link
            to="tel:+8801811530562"
            className="text-[#00215B] text-xl md:text-2xl font-mono"
          >
            <CallIcon /> +880-1811530562
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;

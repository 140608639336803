import React from "react";
import w1 from "../../img/w1.png";
import w2 from "../../img/w2.png";
import w3 from "../../img/w3.png";
import w4 from "../../img/w4.png";
import w5 from "../../img/w5.png";
import w6 from "../../img/w6.png";
import w7 from "../../img/w7.png";
import w8 from "../../img/w8.png";
import w9 from "../../img/w9.png";
import { Fade, Zoom } from "react-reveal";
const FirstDivW = () => {
  const imagesRow1 = [
    { image: w1, alt: "UnderConstructionBuilding" },
    { image: w2, alt: "Crane" },
    { image: w3, alt: "Workers" },
    { image: w4, alt: "Excavator" },
    { image: w5, alt: "Land" },
    { image: w6, alt: "Tallbuilding" },
    { image: w7, alt: "Factory" },
    { image: w8, alt: "Constructionbuilding" },
    { image: w9, alt: "Workingpeople" },
  ];
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 m-auto mt-24 mb-10 w-2/3 lg:w-1/2">
      {imagesRow1.map((el, i) => {
        return (
          <div key={i}>
            {i === 4 ? (
              <Zoom>
                <img src={el.image} alt={el.alt} />
              </Zoom>
            ) : (
              <Fade
                top={i === 0 || i === 1 || i === 2}
                bottom={i === 6 || i === 7 || i === 8}
                right={i === 5}
                left={i === 3}
              >
                <img src={el.image} alt={el.alt} />
              </Fade>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FirstDivW;

import React from "react";
import MapImg from "../img/Map.png";
import CallIcon from "@mui/icons-material/Call";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { Link } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
const Map = () => {
  const contacts = [
    {
      text: "COMPANY PHONE",
      link: "tel:+8801811530562",
      linkText: "+880-1811530562",
      icon: <CallIcon />,
    },
    {
      text: "COMPANY EMAIL",
      link: "mailto:ariantraders1@gmail.com",
      linkText: "ariantraders1@gmail.com",
      icon: <TextsmsIcon />,
    },
  ];
  return (
    <div className="flex flex-col gap-7 lg:w-5/6 m-auto">
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
        <img
          className="h-[300px] md:h-[450px] px-2 m-auto"
          src={MapImg}
          alt="42, Chinadipara, Bara Beraid, Badda, Dhaka-1212"
        />

        <p className="w-5/6 md:w-3/4 m-auto h-max flex flex-col gap-3 px-8 md:px-12 py-6 md:py-8 shadow-md bg-white font-[Kanit] border-t-4 border-[#D2153D]">
          COMPANY ADDRESS
          <a
            href="https://www.google.com/maps/search/?q=42, Chinadipara, Bara Beraid, Badda, Dhaka-1212"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#00215B] text-xl md:text-2xl font-mono"
          >
            <BusinessIcon /> 42, Chinadipara, Bara Beraid, Badda, Dhaka-1212
          </a>
        </p>
      </div>

      <div className="flex flex-row flex-wrap gap-3 lg:gap-6 w-11/12 lg:w-full m-auto lg:justify-around">
        {contacts.map((el, i) => {
          return (
            <div key={i} className="flex gap-3 items-center p-2">
              <div
                className={`w-10 h-10 p-[7px] text-white ${
                  i === 0 ? "bg-[#B92F2C]" : "bg-[#001ED3]"
                } `}
              >
                {el.icon}
              </div>

              <div className="flex flex-col gap-1 font-[Kanit]">
                <p>{el.text}</p>
                <Link
                  to={el.link}
                  className="text-[#00215B] text-xl md:text-2xl font-[Kanit]"
                >
                  {el.linkText}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Map;
